.single-select {
    .react-select__value-container--has-value .css-1wa3eu0-placeholder {
        top: -4px;
        background: $white;
        transition: all 0.15s ease-in-out;
        margin-left: -2px;
        font-size: 12px;
        padding: 0 3px;
        width: auto;
    }

    .react-select__control--menu-is-open .css-1wa3eu0-placeholder {
        top: -4px;
        background: $white;
        transition: all 0.15s ease-in-out;
        margin-left: -2px;
        font-size: 12px;
        padding: 0 3px;
        width: auto;
    }

    .css-g1d714-ValueContainer {
        overflow: inherit !important;
        background: $white;
        padding-left: 13px;
        padding-right: 13px;
    }

    .css-26l3qy-menu {
        margin-top: 0px;
    }
}

.payment-top-outer {
    background: $white;
    width: 100%;
    display: inline-block;
    padding-bottom: 30px;
    padding-top: 20px;

    .report-filter {
        display: flex;
        margin-top: 15px;

        fieldset {
            width: 220px;
            margin-right: 20px;
        }
    }
}

.date-wise-report-table {
    background: $white;
    border-radius: 8px;
    margin-bottom: 20px;
}

.report-data {
    .date-wise-report-table {
        background: $white;
        border-radius: 8px;
        margin-bottom: 20px;

        table {
            // margin-top: 24px;
            table-layout: fixed;

            thead {
                tr {
                    background: #e3e7f1;
                    border-radius: 6px;

                    th {
                        padding: 15px 15px;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 22px;
                        color: $heading-color;
                        white-space: nowrap;
                        text-align: left;

                        &:first-child {
                            border-radius: 8px 0 0 0px;
                        }

                        &:last-child {
                            border-radius: 0px 8px 0px 0;
                        }
                    }
                }
            }

            tbody {
                tr {
                    border-bottom: 1px solid rgba($heading-color, 0.06);

                    td {
                        padding: 13px 15px;
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 22px;
                        color: rgba($heading-color, 0.6);
                        text-align: left;

                        a {
                            color: rgba($heading-color, 0.6);
                        }
                    }

                    &:last-child {
                        border-bottom: 0px;
                    }

                    &:hover {
                        background: rgba(239, 242, 249, 0.4);

                        .btn-view {
                            background: $accent-color;
                            color: $white;

                            a {
                                color: $white;
                            }
                        }

                        td {
                            color: $heading-color;

                            a {
                                text-decoration: underline;
                                color: $heading-color;
                            }
                        }
                    }
                }
            }
        }
    }
}

.btn-line {
    white-space: nowrap;

    a {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &:hover {
        color: $white;

        a {
            color: $white;
        }
    }

}

.table-data-heading {
    padding: 15px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h2 {
        font-weight: 500;
        font-size: 18px;
        line-height: 30px;
        color: $heading-color;
    }

    .btn-line {
        display: flex;
        align-items: center;
        justify-content: space-between;

        i {
            font-size: 18px;
            margin-right: 5px;
        }

        padding: 0px 20px;
    }

    .action-item-btn {
        display: flex;
        align-items: center;

        .btn-line {
            margin-left: 10px;
        }
    }
}

.top-action-btn {
    ul {
        display: flex;

        li {
            background: $white;
            border: 1px solid #d9d9d9;
            border-radius: 4px;
            height: 32px;
            width: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
            margin-left: 15px;
            position: relative;
            cursor: pointer;

            &:first-child {
                margin-right: 0px;
            }

            a {
                color: rgba($heading-color, 0.6)
            }
        }
    }
}

.revenue-top-filter {
    background: $white;
    padding: 20px 30px;
    border-radius: 8px;

    .filter-heading {
        font-weight: 500;
        font-size: 18px;
        line-height: 30px;
        color: $heading-color;
        padding-bottom: 16px;
        display: block;
    }

    .filter-filled-outer {
        display: flex;
        align-items: center;
    }

    .filter-filed {
        display: flex;
        align-items: center;
        width: calc(100% - 340px);

        fieldset {
            width: 100%;
            margin-right: 20px;

            .material {
                .form-label {
                    &:before {
                        top: 12px;
                    }
                }
            }

            .form-input {
                margin-bottom: 0px;
                padding: 10px 15px;

                &:focus,
                &:visited,
                &:focus-within,
                &:focus-visible {
                    ~label {
                        &:before {
                            top: -8px;
                            font-size: 12px;
                        }
                    }
                }

                &:not(:placeholder-shown) {
                    ~label {
                        &:before {
                            top: -8px;
                            font-size: 12px;
                        }
                    }
                }
            }

            .select-selected:not(:placeholder-shown)~label:before,
            .material input[type="number"]:not(:placeholder-shown)~label:before,
            .material input[type="password"]:not(:placeholder-shown)~label:before,
            .material input[type="tel"]:not(:placeholder-shown)~label:before,
            .material input[type="text"]:not(:placeholder-shown)~label:before,
            .material select:not(:placeholder-shown)~label:before,
            .material textarea:not(:placeholder-shown)~label:before {
                top: -8px;
            }
        }
    }

    .btn-submit-reset {
        display: flex;

        .btn-primary {
            min-width: 176px;
            margin-right: 10px;
        }

        .btn-reset {
            background: transparent;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            border: 0px;
            color: $heading-color;
            cursor: pointer;
        }
    }
}

.filter-select-chips {
    ul {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 8px;

        li {
            margin-top: 8px;
            background: $white;
            padding: 6px 6px 6px 17px;
            font-weight: normal;
            font-size: 12px;
            line-height: 18px;
            color: $heading-color;
            display: flex;
            align-items: center;
            border-radius: 16px;
            margin-right: 8px;
            border: 1px solid #dde1e9;

            span {
                background: #e3e7f1;
                height: 20px;
                width: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 9px;
                border-radius: 12px;
            }
        }
    }
}

.revenue-report-summary {
    background: $white;
    padding: 20px 30px;
    margin: 20px 0px;
    border-radius: 8px;

    ul {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: center;

        li {
            label {
                font-weight: 500;
                font-size: 22px;
                line-height: 33px;
                color: $heading-color;
                display: flex;
                align-items: center;
                .currency-symbol {
                    font-weight: 500;
                    font-size: 22px;
                    line-height: 33px;
                    color: $heading-color;
                    display: block;
                    margin-right: 5px;
                    width: 100%;
                    text-align: center;
                }
            }

            span {
                font-weight: normal;
                font-size: 11px;
                line-height: 16px;
                color: rgba($heading-color, 0.6);
            }
        }
    }

    .btn-line {
        display: flex;
        align-items: center;
        justify-content: space-between;

        i {
            font-size: 18px;
            margin-right: 5px;
        }

        padding: 0px 20px;
    }
    &.custom-revenu-report{
        ul { justify-content: center;
        li { padding: 0px 40px;  }
        }
    }
}

.report-table {
    table {
        // margin-top: 24px;
        table-layout: fixed;

        thead {
            tr {
                background: #e3e7f1;
                border-radius: 6px;

                th {
                    padding: 15px 15px;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 22px;
                    color: $heading-color;
                    white-space: nowrap;
                    text-align: left;

                    &:first-child {
                        border-radius: 8px 0 0 0px;
                    }

                    &:last-child {
                        border-radius: 0px 8px 0px 0;
                    }
                }
            }
        }

        tbody {
            tr {
                border-bottom: 1px solid rgba($heading-color, 0.06);

                td {
                    padding: 13px 15px;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 22px;
                    color: rgba($heading-color, 0.6);
                    text-align: left;

                    a {
                        color: rgba($heading-color, 0.6);
                    }
                }

                &:last-child {
                    border-bottom: 0px;
                }

                &:hover {
                    background: rgba(239, 242, 249, 0.4);

                    .btn-view {
                        background: $accent-color;
                        color: $white;

                        a {
                            color: $white;
                        }
                    }

                    td {
                        color: $heading-color;

                        a {
                            text-decoration: underline;
                            color: $heading-color;
                        }
                    }
                }
            }
        }
    }
}

.revenue-report-all-quarter {
    width: 100%;
    overflow: auto;
    background: $white;
    border-radius: 8px;
    height: 500px;

    table {
        thead {
            tr {
                th {
                    white-space: normal;
                    vertical-align: top;
                    position: sticky;
                    top: 0;
                    z-index: 1;
                    background: #e3e7f1;

                    &:first-child {
                        position: sticky;
                        left: 0;
                        z-index: 3;
                    }

                    &:nth-child(2) {
                        position: sticky;
                        left: 150px;
                        z-index: 3;
                    }

                    &:nth-child(3) {
                        position: sticky;
                        left: 300px;
                        z-index: 3;
                    }
                }
            }
        }

        tbody {
            td {
                background: $white;

                &:first-child {
                    position: sticky;
                    left: 0;
                    z-index: 2;
                }

                &:nth-child(2) {
                    position: sticky;
                    left: 150px;
                    z-index: 2;
                }

                &:nth-child(3) {
                    position: sticky;
                    left: 300px;
                    z-index: 2;
                }
            }
        }

        th,
        td {
            width: 150px;
        }
    }
}

.report-table {
    &.revenue-report-table {
        width: 100%;
        overflow: auto;
        background: $white;
        border-radius: 8px;
        height: 500px;

        table {
            border-spacing: 100px;

            tbody {
                td {}
            }

            td {
                padding: 0px;
                background: $white;

                &:nth-child(1) {
                    width: 140px;
                    white-space: normal;
                    padding-left: 15px;
                    padding-right: 15px;
                    position: sticky;
                    position: sticky;
                    left: 0;
                    z-index: 9;
                    background: $white;
                }

                &:nth-child(2) {
                    width: 140px;
                    white-space: normal;
                    padding-left: 15px;
                    padding-right: 15px;
                    position: sticky;
                    left: 140px;
                    z-index: 9;
                    background: $white;
                }

                &:nth-child(3) {
                    width: 120px;
                    white-space: normal;
                    padding-left: 15px;
                    padding-right: 15px;
                    position: sticky;
                    left: 280px;
                    z-index: 9;
                    background: $white;
                }

                &:last-child {
                    .sub-data-heading {
                        tr {
                            border-right: 0px !important;
                        }
                    }
                }
            }

            thead {
                th {
                    vertical-align: top;
                    padding-bottom: 0px;
                    padding-left: 0px;
                    padding-right: 0px;
                    width: 230px;
                    background: #e3e7f1;
                    position: sticky;
                    top: 0;
                    z-index: 9;

                    &:nth-child(1) {
                        width: 140px;
                        white-space: normal;
                        padding-left: 15px;
                        padding-right: 15px;
                        position: sticky;
                        top: 0;
                        z-index: 10;
                        left: 0px;
                    }

                    &:nth-child(2) {
                        width: 140px;
                        white-space: normal;
                        padding-left: 15px;
                        padding-right: 15px;
                        position: sticky;
                        left: 140px;
                        z-index: 10;
                    }

                    &:nth-child(3) {
                        width: 120px;
                        white-space: normal;
                        padding-left: 15px;
                        padding-right: 15px;
                        position: sticky;
                        left: 280px;
                        z-index: 9;
                    }
                    &.fixed-th{
                        z-index: 99;
                    }

                    .sub-data-heading {
                        margin-top: 8px;

                        th {
                            vertical-align: top;
                            white-space: nowrap;
                            padding-left: 15px;
                            border-radius: 0px;
                            background: rgba($white, 0.4);
                            padding-bottom: 10px;
                            position: inherit;

                            &:nth-child(1) {
                                width: auto;
                                z-index: 1;
                            }

                            &:nth-child(2) {
                                width: auto;
                                z-index: 1;
                            }
                        }
                    }

                    &:nth-child(4) {
                        .sub-data-heading {
                            th {
                                &:first-child {
                                    border-radius: 6px 0 0 0;
                                }
                            }
                        }
                    }

                    &:last-child {
                        .sub-data-heading {
                            th {
                                &:last-child {
                                    border-radius: 0 6px 0 0;
                                }
                            }
                        }
                    }

                    span {
                        padding: 0px 15px;
                        white-space: normal;
                        height: 50px;
                        display: inherit;
                        vertical-align: middle;
                    }

                    &:last-child {
                        .sub-data-heading {
                            tr {
                                border-right: 0px !important;
                            }
                        }
                    }
                }
            }

            .sub-data-heading {
                tr {
                    border-right: 1px solid rgba($heading-color, 0.1) !important;

                    td {
                        padding-top: 13px;
                        padding-bottom: 13px;
                        z-index: 1;

                        &:nth-child(1) {
                            width: auto;
                            z-index: 1;
                        }

                        &:nth-child(2) {
                            width: auto;
                            z-index: 1;
                        }
                    }
                }
            }
        }
    }
}

.more-filter {
    // display: flex;
    // align-items: center;
    // color: $heading-color;
    margin: 0px 0px 0px 10px;

    .filter-heading {
        margin-left: 0px;
        padding: 20px 0 10px;
        display: block;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $heading-color;
        width: 100%;
    }

    .more-filter-txt,
    .leass-filter-txt {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        margin-left: 5px;
        white-space: nowrap;
        margin-right: 20px;
    }

    .dropdown {
        display: flex;
        position: relative;

        .dropdown-toggle {
            display: flex;
            align-items: center;
            background: transparent;
            border: 0px;

            &::before {
                content: "\e907";
                font-family: dc !important;
            }
        }

        .leass-filter-txt {
            display: none;
        }

        .dropdown-menu {
            box-shadow: 0px 2px 14px rgba(31, 45, 78, 0.14);
            border-radius: 6px;
            width: 350px;
            padding: 30px;
            background: $white;
            // margin-top: 33px;
            z-index: 99;
        }

        &.show {
            .dropdown-menu {
                display: block;
                position: absolute !important;
                // right: 0px !important;
                top: 18px !important;
                transform: translate(-44%, 48px) !important;

                // margin-top: 33px;
            }

            .dropdown-toggle {
                &::before {
                    content: "\e90c";
                    font-family: dc !important;
                }
            }

            .leass-filter-txt {
                display: block;
            }

            .more-filter-txt {
                display: none;
            }
        }
    }

    .more-filter-option {
        h3 {
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: $heading-color;
            display: block;
        }
    }

    .material {
        .form-input {
            padding: 10px 15px;
        }

        .form-label:before {
            top: 12px;
        }
    }
}

.show.dropdown .top-btn-none {
    opacity: 0;
}

.p-lr-0 {
    padding-left: 0px !important;
    padding-right: 0px;
}

.info-icn {
    position: relative;
    top: 1px;
}

.convertet-tootip-bx {
    &.tootip-outer {
        &.tooltip-payment {
            .react-tooltip-lite {
                width: 355px !important;
                padding: 30px !important;
            }

            .react-tooltip-lite-arrow {
                border-top: 0px !important;
                border-bottom: 10px solid #fff !important;
            }

            .converter-tootip {
                .switch-link {
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 18px;
                    color: rgba($heading-color, 0.6);
                    margin-left: 40px;
                    text-decoration: underline;
                    cursor: pointer;
                }

                .tool-tip-heading {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 20px;
                    color: $heading-color;
                }

                .calculate-result {
                    padding-top: 25px;
                    display: flex;
                    align-items: center;
                    padding-bottom: 20px;

                    li {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 20px;
                        color: $heading-color;
                        padding-right: 14px;

                        input {
                            background: #ffffff;
                            border: 1px solid #bbbbbb;
                            box-sizing: border-box;
                            border-radius: 8px;
                            padding: 10px;
                            width: 124px;
                        }
                    }
                }

                .btn-primary {
                    min-width: 150px;
                }
            }
        }
    }
}

.mixed-chart {
    background: $white;
    padding: 40px 20px;

    h2 {
        font-weight: 500;
        font-size: 22px;
        line-height: 30px;
        color: $heading-color;
        padding-bottom: 40px;
        display: block;
        text-align: center;
    }

    .apexcharts-legend-series {
        padding: 25px 30px !important;
    }
}

.filter-add {
    background: #23c133;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    position: absolute;
    top: -4px;
    right: -4px;
}

.multiselect-dropDown {
    .css-48ayfv+div {
        z-index: 99 !important;
    }
}

.tooltip-payment {
    height: 0px !important;
}

.revenue-th-txt {
    padding: 0px;
}

.revenue-report-outer {
    position: relative;
    background: $white;
    border-radius: 8px;

    &.overflow-hidden {
        overflow: hidden;

        .report-table.revenue-report-table {
            overflow: hidden;
        }
    }

    .loader {
        position: absolute;
        top: 0%;
        background: rgba(0, 0, 0, 0.5);
        height: 100%;
    }
}

.export-modal-popup {
    .modal {
        z-index: 99;

        .modal-body {
            min-height: 170px;
        }
    }
}

.not-found {
    text-align: center;
    color: red;
    font-size: 18px;
}

.additional-revenue-outer {
    .report-table {
        &.revenue-report-table {
            table {
                thead {
                    th {
                        &:nth-child(3) {
                            position: sticky;
                            width: 230px;
                            left: initial;
                            padding-right: 0px;
                        }
                        &:nth-child(6) {
                            width: 140px;
                        }
                        span{
                            vertical-align: top;
                            height: 30px;
                        }
                        &:last-child{
                            width: 80px;
                            padding-left: 15px;
                            padding-right: 15px;
                        }
                    }
                }
                tbody {
                    td {
                        &:nth-child(3) {
                            position: inherit;
                            width: 230px;
                            left: initial;
                            padding-right: 0px;
                        }
                        &:nth-child(6) {
                            padding-left: 10px;
                            padding-right: 10px;
                        }
                        &:last-child{
                            padding-left: 10px;
                            padding-right: 10px;
                        }
                    }
                }
            }
        }
    }
}
.drop-down__menu {
    z-index: 10 !important;
}
.report-export {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    width: 100%;
    li {
        label {
            font-weight: 500;
            font-size: 22px;
            line-height: 33px;
            color: $heading-color;
            display: block;
            font-style: italic;
        }

        // span {
        //     font-weight: 500;
        //     font-size: 22px;
        //     line-height: 33px;
        //     color: $heading-color;
        //     display: block;
        // }
    }
    .btn-line i {
        font-size: 18px;
        margin-right: 5px;
    }
    .btn-line {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
    }
}
.d-block {
    display: block;
}
.payment-revenue-report-table {
    &.report-table {
        &.revenue-report-table{
            table {
                thead {
                    th {
                        &:nth-child(3){
                            z-index: 10;
                        }
                    }
                }
            }
        }
    }
}

.line-clamp{
    display: block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    position: relative;
    line-height: 1.5;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0;
    text-decoration: initial;
    outline: initial;
}

.line-clamp-2 {
    -webkit-line-clamp: 2;
    height: calc(
1em
    * 1.5 * 2);
}
.line-clamp-3 {
    -webkit-line-clamp: 3;
    height: calc(
1em
    * 1.5 * 3);
}

.line-clamp-4 {
    -webkit-line-clamp: 4;
    height: calc(
1em
    * 1.5 * 4);
}

.additional-revenue-history-popup .loader {
    left: 0px;
    position: absolute;
    top: 0%;
    background: rgba(0, 0, 0, 0.5);
    height: 100%;
}

.edit-allocation-btn {
  color: #0bbddd;
  transition: color 0.3s ease;
}
  
.edit-allocation-btn:hover {
  color: white;
}

.additional-more-filter {
    width: 100% !important;
}

.additional-banner-message {
    // color: ;
    display: inherit !important;
    color: inherit !important;
}

.relative {
    position: relative;
}

.amount-percentage-container {
    display: flex;
    flex-direction: column;
    // align-items: center; /* Centers content */
    white-space: nowrap; /* Prevents wrapping */
  }
  
  .amount {
    display: block;
  }
  
  .percentage {
    display: block;
    font-size: 12px;
  }
  