.hold-amt-sec {
    display: flex;
    align-items: center;

    .material {
        padding-bottom: 0px;

        .form-input {
            padding: 10px 15px;
            width: 140px;
        }

    }

    .action-btn {
        margin-left: 10px;

        .ic-check {
            color: $accent-color;
        }

        .ic-clearclose {
            color: $promotional-color-2;
        }
    }
}

.dealer-wallet-table {
    table {
        th {
            &:nth-child(5) {
                width: 200px;
            }
        }

        tr {
            &:hover {
                td {
                    background: rgba(239, 242, 249, 0.4);

                    a {
                        text-decoration: none !important;
                        color: initial !important;
                    }
                }

            }
        }
    }

    :hover {
        .action-btn {
            background: initial;

            li {
                &:hover {
                    background: $accent-color;
                    color: $white;

                    .ic-check {
                        color: $white;
                    }

                    .ic-clearclose {
                        color: $white;
                    }
                    a{
                        background: $accent-color;
                        color: $white !important;
    
                    }
                }
            }
        }
    }
}

.dealer-gcd-top-riben {
    ul {
        justify-content: flex-start;

        li {
            margin-right: 40px;
            text-align: left;

            label {
                .currency-symbol {
                    text-align: left !important;
                }
            }
        }
    }
}

.gcd-arrow-up {
    transform: rotate(270deg);
    display: inline-block;
    color: #D73D2D;
    font-weight: bold;
}

.gcd-arrow-down {
    transform: rotate(270deg);
    display: inline-block;
    color: #72D73E;
    font-weight: bold;
}

.gcd-transction-history-popup {
    .nav-pills {
        border-bottom: 0px;
    }

    table {
        tbody {
            tr {
                background: rgba(239, 242, 249, 0.5);

                td {

                    &:nth-child(2) {
                        border-radius: 8px 0 0 8px;
                    }

                }
            }
        }
    }
}

.dealer-outbond-table-outer {
    .financer-tab-line {
        border-bottom: 1px solid $border-color;

        .nav-link {
            padding-bottom: 10px !important;
        }
    }

    table {
        tbody {
            td{
                &:last-child{
                     padding-bottom: 0px;
                }
                &:first-child{
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                
            }
            .subtable {
                tr{
                    td{
                        padding-bottom: 13px;
                        &:nth-child(2){
                            padding-top: 0px !important;
                            padding-bottom: 0px !important;
                        }
                        
                    }
                }

                td {
                    background: $white;
                    table{
                        border-right: 1px solid $border-color;
                        border-left: 1px solid $border-color;
                        tbody{
                            tr{
                                border-bottom: 1px solid $border-color;
                                &:last-child{
                                    border-bottom: 0px;
                                }
                            }
                        }
                    }

                }
            }
        }
    }
}
.p-13{
    padding-bottom: 13px !important;
}
.lost-disbral-dealer-table{
    width: 100%;
    overflow-x: auto;
    table{
        th{
            background: #e3e7f1;
        }
        td{
            background: $white;
        }
        
        th,td{
            min-width: auto;
            &:nth-child(1){
                width: 100px !important;
                position: -webkit-sticky;
                position: sticky;
                left: 0;
                z-index: 9;
            }
            &:nth-child(2){
                width: 125px;
                position: -webkit-sticky;
                position: sticky;
                left: 100px;
                z-index: 9;
            }
            &:nth-child(3){
                width: 145px;
            }
            &:nth-child(4){
                width: 125px;
            }
            &:nth-child(5){
                width: 150px;
            }
            &:nth-child(6){
                width: 145px;
            }
            &:nth-child(7){
                width: 155px;
            }
            &:nth-child(8){
                // width: 180px !important;
                width: 203px !important;
            }
            &:nth-child(9){
                width: 130px;
            }
            &:nth-child(10){
                width: 130px;
            }
            &:nth-child(11){
                width: 170px;
            }
            &:nth-child(12){
                width: 130px;
            }
            &:nth-child(13){
                width: 170px;
            }
            &:nth-child(14){
                width: 160px;
            }
        }
    }
}
.payment-requet-dealer{
    width: 175px !important;
}

.dealer-wallet-subtable{
    td{
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
    .subtable-in-table{
        padding-left: 0px !important;
        padding-right: 0px !important;
        tr{
            td{
                &:nth-child(3){
                    padding-left: 25px !important;
                    padding-right: 25px !important;
                }
                &:nth-child(4){
                    padding-left: 25px !important;
                    padding-right: 25px !important;
                }
            }
            
        }
        
    }
}