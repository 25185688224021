.finance-report-outer {
    .white-bg {
        background: $white;
    }

    .financer-tab-line {
        padding-top: 15px;
        padding-bottom: 10px;
    }

    .material {
        .form-label {
            &:before {
                top: 11px;
            }
        }
    }

    .tab-line {
        .tab-list {
            display: flex;
            align-items: center;

            .nav-item {
                margin-right: 50px;
                cursor: pointer;

                .nav-link {
                    border-bottom: 2px solid transparent;
                    padding-bottom: 8px;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 20px;
                    color: rgba($heading-color, 0.87);

                    &.active {
                        font-weight: 600;
                        color: rgba(11, 189, 221, 0.87);
                        border-bottom: 2px solid #0bbddd;
                    }
                }
            }
        }
    }

    .tab-bg {
        margin-top: 20px;
        margin-bottom: 20px;

        .tab-list {
            display: flex;
            align-items: center;
        }

        .nav-item {
            margin-right: 10px;
            cursor: pointer;

            .nav-link {
                border-radius: 100px;
                background: $white;
                font-weight: normal;
                font-size: 12px;
                color: $heading-color;
                border-bottom: 0px;
                height: 36px;
                padding: 0px 20px;
                display: flex;
                align-items: center;
                justify-content: center;

                &.active {
                    font-weight: 500;
                    background: #0bbddd;
                    color: $white;
                }
            }
        }
    }

    .payment-pending-table {
        .ic-keyboard_arrow_down {
            font-size: 24px;
            position: relative;
            top: 5px;
            margin-right: 8px;
        }

        .advance-txt {
            color: #e88107;
        }

        .adhoc-txt {
            color: #e02020;
        }

        .normal-txt {
            color: green;
        }
    }

    table {
        // margin-top: 24px;
        table-layout: fixed;

        th,
        td {
            width: 20%;
            // min-width: 165px;
            min-width: 128px;

            &:last-child {
                min-width: 115px;
            }
        }

        thead {
            tr {
                background: #e3e7f1;
                border-radius: 6px;

                th {
                    padding: 15px 15px;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 22px;
                    color: $heading-color;
                    white-space: nowrap;
                    text-align: left;

                    &:first-child {
                        border-radius: 6px 0 0 6px;
                    }

                    &:last-child {
                        border-radius: 0px 6px 6px 0;
                    }
                }
            }
        }

        tbody {
            tr {
                border-bottom: 1px solid rgba($heading-color, 0.06);
                background: $white;

                td {
                    padding: 13px 15px;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 22px;
                    color: rgba($heading-color, 0.6);
                    text-align: left;
                    // &:nth-child(5) {
                    //   width: 110px;
                    // }
                }

                .loan-id-arrow {
                    &::before {
                        content: "\e917";
                        font-family: dc !important;
                        font-size: 24px;
                        position: relative;
                        top: 6px;
                        margin-right: 8px;
                    }
                }

                &:last-child {
                    border-bottom: 0px;
                }

                // &:first-child {
                //   td {
                //     padding-top: 19px;
                //   }
                // }
                &.active {
                    background: rgba(239, 242, 249, 0.4);
                    border-bottom: 0px;

                    .loan-id-arrow {
                        &::before {
                            content: "\e91a";
                            font-family: dc !important;
                            font-size: 24px;
                            position: relative;
                            top: 6px;
                            margin-right: 8px;
                        }
                    }
                }
            }

            .subtable {
                thead {
                    tr {
                        background: #e3e7f1;
                    }
                }

                td {
                    background: rgba(239, 242, 249, 0.4);
                    padding-left: 30px;
                    padding-right: 30px;

                    table {
                        tr {
                            th {
                                &:nth-child(1) {
                                    width: 24%;
                                }
                            }
                        }
                    }
                }

                table {
                    width: 100%;
                    overflow-y: auto;
                    display: block;

                    tbody {
                        td {
                            background: $white !important;
                            padding-left: 15px !important;
                            padding-right: 15px !important;
                        }
                    }
                }
            }
        }

        .link-txt {
            text-decoration: underline;
            cursor: pointer;
        }

        .disabled-txt {
            opacity: 0.5;
        }
    }

    .multiselect-dropDown {
        .css-48ayfv+div {
            width: 100%;
        }
        &.financier-name-select{.css-48ayfv+div {
            width: auto;
        }}
    }
    

    .loan-history-table {
        table {
            tbody {
                tr {
                    td {
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
    }
}

.single-select {
    .react-select__value-container--has-value .css-1wa3eu0-placeholder {
        top: -5px;
        background: $white;
        transition: all 0.15s ease-in-out;
        margin-left: -2px;
        font-size: 12px;
        padding: 0 3px;
        width: auto;
    }

    .react-select__control--menu-is-open .css-1wa3eu0-placeholder {
        top: -5px;
        background: $white;
        transition: all 0.15s ease-in-out;
        margin-left: -2px;
        font-size: 12px;
        padding: 0 3px;
        width: auto;
    }

    .react-select__value-container {
        overflow: inherit !important;
        background: $white;
        padding-left: 13px;
        padding-right: 13px;
    }

    .css-26l3qy-menu {
        margin-top: 0px;
    }
}

.report-filter-filed {
    display: flex;
    align-items: center;

    fieldset {
        width: 100%;
        margin-right: 16px;

        .material {
            .form-input {
                padding: 10px 15px;
                margin-bottom: 0px;
            }
        }
    }

    .btn-submit-reset {
        display: flex;
        align-items: center;

        .btn-primary {
            min-width: 180px;
        }

        .btn-reset {
            background: transparent;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            border: 0px;
            color: $heading-color;
            cursor: pointer;
            margin-left: 10px;
        }
    }
}

.report-filter-outer {
    background: $white;
    padding: 20px 30px;
    display: block;
    width: 100%;
    border-radius: 8px;
    margin-bottom: 20px;

    .sub-heading {
        font-weight: 500;
        font-size: 18px;
        line-height: 30px;
        color: $heading-color;
        display: block;
        // padding-bottom: 15px;
    }

    .filter-heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 0px 15px;
    }

    .selected-chips {
        ul {
            display: flex;
            align-items: center;
            padding: 10px 0 0 0;
            flex-wrap: wrap;

            li {
                background: $white;
                padding: 6px 6px 6px 17px;
                font-weight: normal;
                font-size: 12px;
                line-height: 18px;
                color: $heading-color;
                display: flex;
                align-items: center;
                border-radius: 16px;
                margin-right: 8px;
                border: 1px solid #dde1e9;
                margin-top: 10px;

                span {
                    background: rgba(227, 231, 241, 0.6);
                    height: 20px;
                    width: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-left: 9px;
                    border-radius: 12px;
                    cursor: pointer;
                }
            }
        }
    }
}

.display-block {
    display: block;
}

.display-none {
    display: none;
}

.update-payment-popup,
.data-download-popup {
    .modal {
        .modal-main {
            padding: 40px;
            border-radius: 8px;
            width: 700px;
        }

        .modal-body {
            // overflow-y: inherit;
            padding-top: 5px;
        }

        .modal-header {
            padding-bottom: 20px;

            .sub-heading {
                font-weight: normal;
                font-size: 14px;
                line-height: 20px;
                color: rgba($heading-color, 0.87);
                display: block;
            }
        }
    }

    fieldset {
        margin-bottom: 20px;
    }

    .material {
        .form-input {
            padding: 10px 15px;
            margin-bottom: 0px;
        }

        .form-label {
            &:before {
                top: 12px;
            }
        }
    }

    .col-md-6,
    .col-md-12 {
        padding-right: 10px;
        padding-left: 10px;
    }

    .row {
        margin-right: -10px;
        margin-left: -10px;
    }
}

.data-download-popup .modal {
    .modal-main {
        width: 500px;
    }

    .modal-body {
        height: 200px
    }
}

.document-view-popup {
    .modal {
        .modal-main {
            height: 98vh;
            width: 820px;
        }
    }

    .modal-header {
        padding-bottom: 0px !important;
    }

    .category-select-bx {
        margin-bottom: 20px;
    }

    .pan-container {
        height: 100% !important;
        // overflow: auto;
    }

    .modal-body {
        width: 100%;
        max-height: initial;
        margin-left: 0;
        padding: 0px;
        height: calc(100% - 130px);
        overflow: hidden;
        // *:not(button) {
        //     height: 100%;
        //     text-align: center;
        //     margin: 0 auto;
        // }
    }

    .image-opt {
        top: 60%;

        ul {
            height: auto !important;

            .icn-pre-slide {
                position: absolute;
                left: 0px;
            }

            .icn-next-slide {
                right: 0px;
                position: absolute;
            }
        }
    }

    .image-varification-slider .pfd-img-height>div>div {
        right: 44% !important;
        bottom: 10px !important;
    }

    .pfd-img-height>div,
    .slider-outer,
    .carousel,
    .carousel__slides,
    .carousel__slide,
    .pfd-img-height {
        height: 100%;
    }

    .slick-arrow {
        font-size: 0px;
        background: #ffffff;
        box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15);
        transform: matrix(-1, 0, 0, 1, 0, 0);
        border: 0px;
        width: 60px;
        height: 60px;
        border-radius: 100%;
        position: absolute;
        z-index: 9;

        &.slick-prev {
            left: 20px;
            top: 50%;
            transform: translateY(-50%);

            &::before {
                content: "\e918";
                font-family: dc !important;
                font-size: 40px;
                color: $heading-color;
            }
        }

        &.slick-next {
            right: 20px;
            top: 50%;
            transform: translateY(-50%);

            &::before {
                content: "\e919";
                font-family: dc !important;
                font-size: 40px;
                color: $heading-color;
            }
        }

        &.slick-disabled {
            display: none;
            opacity: 0;
        }
    }
}

.inbound-paymentpendid-report {
    .accordion {
        .accordion-item {
            margin-bottom: 40px;
            position: relative;

            .accordion-header {
                .accordion-button {
                    background: transparent;
                    border: 0px;
                    font-weight: 500;
                    font-size: 22px;
                    line-height: 30px;
                    color: $heading-color;

                    &::after {
                        content: "\e91a";
                        font-family: dc !important;
                        font-size: 30px;
                        position: relative;
                        top: 6px;
                        margin-left: 8px;
                    }

                    &.collapsed {
                        &::after {
                            content: "\e917";
                            font-family: dc !important;
                            font-size: 30px;
                            position: relative;
                            top: 6px;
                            margin-left: 8px;
                        }
                    }
                }
            }

            .accordion-body {
                .payment-pending-table {
                    margin-top: 20px;
                }
            }
        }

        .add-payment-btn {
            position: absolute;
            right: 0px;
            top: -4px;
        }
    }

    .finance-history-table,
    .loan-history-table {
        .payment-pending-table {
            margin-top: 0px !important;
        }
    }
}

.ops-pending-table {
    table {
        .subtable {
            td {
                table {
                    width: 100%;
                    display: block;

                    thead {
                        display: inline-block;
                        width: 100%;
                    }

                    tbody {
                        height: 200px;
                        display: inline-block;
                        width: 100%;
                        // overflow-y: auto;
                    }
                }

                th,
                td {
                    &:nth-child(1) {
                        width: 100px !important;
                        min-width: 100px;
                    }
                }

                th,
                td {
                    min-width: 140px;
                    white-space: normal;
                    text-align: center;
                }
            }
        }
    }

    .report-filter-filed {
        fieldset {
            width: 20%;
        }
    }
}

.slider-action-item {
    ul {
        display: flex;
        align-items: center;
        justify-content: center;
        height: auto !important;

        li {
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
            border-radius: 100%;
            width: 30px !important;
            height: 30px !important;
            background: $white;
            margin: 10px !important;

            a {
                color: $heading-color;
                font-size: 20px;
                display: flex;
                align-items: center;
                justify-content: center;

                i {
                    height: auto !important;
                }
            }
        }
    }
}

.finance-radio {
    &.radio-type-txt {
        display: flex;
        align-items: center;
        padding-bottom: 0px;
        flex-wrap: wrap;

        .form-label {
            font-weight: 500;
            font-size: 13px;
            line-height: 19px;
            color: $heading-color;
            display: block;
            width: 100%;
            padding-bottom: 8px;
        }

        .custom-control-label {
            padding-left: 30px;
        }

        .custom-checkbox {
            margin-right: 35px;
        }
    }

    .custom-control-input:checked~.custom-control-label::before {
        color: #fff;
        border-color: #0bbddd;
        background-color: transparent;
    }

    .custom-checkbox .custom-control-input:checked~.custom-control-label::after {
        background: #0bbddd;
        height: 11px;
        width: 11px;
        border-radius: 100%;
        content: "";
        display: flex;
        align-items: center;
        justify-content: center;
        left: 4px;
        top: 4px;
    }

    .custom-checkbox .custom-control-input:checked~.custom-control-label::after {
        font-size: 10px;
        line-height: 21px;
    }
}

.financer-name-filed-radio {
    display: flex;
    align-items: center;
    width: 100%;
}

.select-drop-list {
    width: 130px;

    .react-select__control {
        height: 32px !important;
        min-height: 32px !important;
        border-radius: 8px !important;
        outline: none !important;
        box-shadow: none;
        border-color: #bbbbbb !important;
        overflow: hidden;
    }

    .react-select__value-container {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }
}

.payment-paid-filter {
    .multiselect-dropDown {

        .css-1vr111p-option,
        .css-1jllj6i-control,
        .css-1qprcsu-option,
        .css-6y1x9t-control {
            min-width: auto;
        }
    }
}

.allocation-peding-outer {
    .table-heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 0px;
    }

    .table-action-btn {
        display: flex;
        align-items: center;

        .btn-line {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0px 20px;
            margin-left: 10px;

            i {
                margin-right: 5px;
                font-size: 18px;
            }
        }
    }
}

.alloction-blank-bx {
    min-height: 350px;
    display: flex;

    // display: none;
    table {
        tbody {
            tr {
                td {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 30px;
                    color: #000;
                    text-align: center;
                }
            }
        }
    }
}

.finance-report-outer {
    .allocation-peding-table-outer {
        .allocation-data-table {
            table {
                tr {

                    th,
                    td {
                        width: 12%;

                        &:nth-child(6) {
                            // width: 18%;
                            // width: 200px;
                            width: 65px;
                        }
                    }
                }

                .loan-id-arrow {
                    &::before {
                        display: none;
                    }

                    &::after {
                        content: "\e917";
                        font-family: dc !important;
                        font-size: 24px;
                        position: relative;
                        top: 6px;
                        margin-left: 5px;
                    }
                }
            }
        }
    }
}

.tootip-outer {
    .react-tooltip-lite {
        background: $white;
        width: 250px !important;
        padding: 20px !important;
        // margin-left: 100px;
        box-shadow: 0px 2px 14px rgba(31, 45, 78, 0.14);
        border-radius: 6px;
    }

    .react-tooltip-lite-arrow {
        // border-bottom: 10px solid $white !important;
        // margin-left: 130px;
    }

    .controlled-example_close-button {
        position: absolute;
        top: 5px;
        right: 5px;
        font-size: 15px;
    }

    .tooltip-data {
        p {
            font-weight: normal;
            font-size: 12px;
            line-height: 18px;
            color: rgba($heading-color, 0.5);
        }
    }
}

.update-payment-popup {
    &.allocation-finance-history-table {
        .modal {
            .modal-main {
                width: 750px;

                .payment-pending-table {
                    table {
                        thead {
                            tr {
                                th {
                                    &:first-child {
                                        border-radius: 6px 0 0 0 !important;
                                    }

                                    &:last-child {
                                        border-radius: 0px 6px 0 0 !important;
                                    }
                                }
                            }
                        }
                    }

                    tbody {
                        border: 1px solid #e2e2e2;
                    }
                }
            }
        }
    }
}

.update-payment-popup {
    &.payment-allocation-select-table {
        .modal {
            .modal-main {
                width: 1180px;
            }
        }

        .financer-tab-line {
            border-bottom: 1px solid rgba($heading-color, 0.1);
            padding-bottom: 8px;
            margin-bottom: 25px;
            padding-top: 0;
        }

        .tab-line {
            .tab-list {
                .nav-item {
                    .nav-link {
                        font-size: 14px;

                        &.active {
                            font-weight: 500;
                        }
                    }
                }
            }
        }

        .allocation-payment-detail {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 25px;

            ul {
                display: flex;

                li {
                    padding-right: 40px;

                    label {
                        font-weight: normal;
                        font-size: 11px;
                        line-height: 16px;
                        color: rgba($heading-color, 0.6);
                        display: block;
                    }

                    span {
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 24px;
                        color: $heading-color;
                        display: block;
                    }

                    .ellipsis-text {
                        max-width: 90px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
            }

            .link-txt {
                text-decoration: underline;
                cursor: pointer;
            }

            .search-panel-right {
                position: relative;

                input {
                    border: 1px solid #bbbbbb;
                    border-radius: 8px;
                    padding: 10px 10px 10px 40px;
                }

                .search-icn {
                    position: absolute;
                    left: 11px;
                    top: 8px;
                    font-size: 24px;
                }
            }
        }

        .close-loan-id-popup,
        .loan-history-table {
            .modal {
                .modal-main {
                    width: 660px;
                }
            }
        }
    }

    &.inbound-doc-popup {
        .modal {
            .modal-main {
                width: 800px;
            }
        }
    }
}

.text-nowarp {
    white-space: nowrap;
}

.btn-bottom {
    margin-top: 25px;
}

.finance-report-outer {
    .payment-inbound-table {
        width: 100%;
        overflow-y: auto;
        border-radius: 8px;
        // max-height: 270px;
        // overflow-x: hidden;

        table {
            thead {
                tr {
                    th {
                        &:first-child {
                            border-radius: 6px 0 0 0;
                            width: 110px;
                        }

                        &:last-child {
                            border-radius: 0px 6px 0 0;
                        }

                        white-space: normal;
                        vertical-align: top;
                        position: -webkit-sticky;
                        position: sticky;
                        top: 0;
                        background: #e3e7f1;
                        z-index: 1;
                    }
                }
            }

            .sub-data-heading {
                tbody {
                    border-bottom: 0px;
                }

                tr {
                    border-right: 1px solid rgba($heading-color, 0.1);

                    &:last-child {
                        border-bottom: 0px;
                    }
                }
            }

            tbody {
                border: 1px solid rgba($heading-color, 0.1);
                border-top: 0px;

                tr {
                    td {
                        padding: 0px 15px;

                        &:nth-child(2) {
                            width: 300px !important;
                            padding-bottom: 0;
                            padding-left: 0;
                            padding-right: 0;
                        }

                        &:nth-child(3) {
                            width: 300px !important;
                            padding-bottom: 0;
                            padding-left: 0;
                            padding-right: 0;
                        }

                        &:nth-child(4) {
                            width: 400px !important;
                            padding-bottom: 0;
                            padding-left: 0;
                            padding-right: 0;
                        }

                        &:nth-child(6) {
                            width: 75px !important;
                        }

                        &:nth-child(5) {
                            width: 105px !important;
                        }

                        .sub-data-heading {
                            tbody {
                                tr {
                                    border-right: 1px solid rgba($heading-color, 0.1);
                                    background: rgba(255, 255, 255, 0.4);

                                    td {
                                        width: 96px !important;
                                        padding-top: 13px;
                                        padding-bottom: 13px;
                                        min-width: 96px;
                                        padding-left: 15px;
                                    }
                                }
                            }
                        }
                    }

                    &.active {
                        td {
                            background-color: rgba(239, 242, 249, 0.4);
                            height: 59px;

                            &:first-child {
                                color: #0bbddd;
                            }

                            .sub-data-heading {
                                tr {
                                    td {
                                        &:first-child {
                                            color: rgba($heading-color, 0.6);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .custom-control-label::after {
                    top: 1px;
                    left: 2px;
                }

                .custom-checkbox .custom-control-input:checked~.custom-control-label::after {
                    font-size: 12px;
                }

                .custom-control-label::before {
                    height: 16px;
                    width: 16px;
                }
            }

            thead {
                tr {
                    th {
                        vertical-align: top;
                        min-width: auto;

                        &:nth-child(2) {
                            width: 300px;
                            padding-bottom: 0;
                            padding-left: 0;
                            padding-right: 0;
                        }

                        &:nth-child(3) {
                            width: 300px;
                            padding-bottom: 0;
                            padding-left: 0;
                            padding-right: 0;
                        }

                        &:nth-child(6) {
                            width: 150px;
                        }

                        &:nth-child(5) {
                            width: 150px;
                        }

                        &:nth-child(7) {
                            width: 150px;
                        }
                        &:nth-child(8) {
                            width: 150px;
                        }

                        span {
                            padding: 0 15px 15px;
                            white-space: normal;
                            display: block;
                            text-align: center;
                        }

                        .sub-data-heading {
                            tbody {
                                tr {
                                    border-right: 1px solid rgba($heading-color, 0.1);
                                    background: rgba(255, 255, 255, 0.4);

                                    th {
                                        vertical-align: top;
                                        white-space: nowrap;
                                        padding-left: 15px;
                                        border-radius: 0;
                                        background: rgba(255, 255, 255, 0.4);
                                        padding-bottom: 10px;
                                        position: inherit;
                                        width: 96px;

                                        &:last-child {
                                            min-width: inherit;
                                        }
                                    }

                                    td {
                                        width: 96px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &.uc-refinace-table {
            table {
                thead {
                    tr {
                        th {
                            &:nth-child(3) {
                                width: 20% !important;
                                min-width: auto;
                                padding-left: 15px !important;
                                padding-right: 15px !important;

                            }
                        }

                        .sub-data-heading {
                            tbody {
                                tr {
                                    th {
                                        width: 96px !important;
                                    }
                                }
                            }
                        }


                    }
                }

                tbody {
                    tr {
                        td {
                            &:nth-child(3) {
                                width: 20% !important;
                                padding-left: 15px !important;
                                padding-right: 15px !important;

                            }
                        }

                        .sub-data-heading {
                            tbody {
                                tr {
                                    td {
                                        width: 96px !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .allocation-data-table {
        table {
            thead {
                tr {
                    th {
                        &:nth-child(5) {
                            // width: 150px;
                            width: 105px;
                        }
                    }
                }
            }

            tbody {
                tr {
                    &.active {
                        td {
                            background: $white;
                        }
                    }
                }
            }
        }

        .payment-inbound-table {

            td,
            th {
                &:last-child {
                    width: 85px;
                }
            }

            table {
                tbody {
                    tr {
                        border-bottom: 1px solid rgba($heading-color, 0.06);
                    }
                }
            }
        }

        .action-btn {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: 20px;

            button {
                margin-left: 10px;
            }
        }
    }
}

.txt-value-input {
    background: #ffffff;
    border: 1px solid #bbbbbb;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 10px;
    width: 130px;

    &[type="text"]:disabled {
        background: $table-bg;
    }
}

.total-disbusal-sec {
    display: flex;
    align-items: center;
    margin-top: 20px;
    justify-content: space-between;
    background: #fee7e7;
    border: 1px solid #efd3d3;
    border-radius: 8px;
    padding: 0px 20px;

    .disural-txt {
        display: flex;
        align-items: center;

        i {
            margin-right: 10px;
        }

        p {
            font-weight: normal;
            font-size: 12px;
            line-height: 18px;
            color: rgba($heading-color, 0.8);
        }
    }

    ul {
        display: flex;
        align-items: center;
        padding: 13px 30px;
        border-right: 1px solid #efd2d2;

        li {
            padding: 0px 10px;

            label {
                font-weight: normal;
                font-size: 11px;
                line-height: 16px;
                display: block;
                color: rgba($heading-color, 0.6);
            }

            span {
                font-weight: normal;
                font-size: 12px;
                line-height: 18px;
                color: #3e3e3e;
                display: block;
            }
        }
    }

    .remaing-amt {
        font-weight: 500;
        font-size: 13px;
        line-height: 19px;
        padding: 0px 20px;

        span {
            display: block;
            color: #cd291b;
        }
    }

    &.remainig-amt-nill {
        background: #e8ffef;
        border: 1px solid #c0e7cc;
        border-radius: 8px;

        ul {
            border-right: 1px solid #c0e7cc;
        }

        .remaing-amt {
            font-weight: 500;
            font-size: 13px;
            line-height: 19px;
            color: #1c9e40;
            padding: 0px 20px;
        }
    }
}

.update-payment-popup {
    &.finance-payment-detail-popup {
        .modal {
            .modal-main {
                padding: 40px;
                border-radius: 8px;
                width: 750px;
            }
        }
    }
}

.finance-report-outer {
    .allocation-peding-table-outer {
        .Allocation-Completed-table {
            &.allocation-data-table {
                table {
                    tr {

                        td,
                        th {
                            width: 15%;

                            &:nth-child(6) {
                                width: 22%;
                            }
                        }
                    }
                }
            }
        }

        .payment-inbound-table {
            table {
                thead {
                    tr {
                        th {
                            &:nth-child(2) {
                                width: 300px;
                            }

                            &:nth-child(3) {
                                width: 300px;
                            }

                            &:nth-child(4) {
                                // width: 400px;
                                width: 360px;
                                padding-bottom: 0;
                                padding-left: 0;
                                padding-right: 0;
                            }
                        }

                        .sub-data-heading {
                            tbody {
                                tr {
                                    th {
                                        width: 96px;
                                    }
                                }
                            }
                        }
                    }
                }
            }


        }

        .lost-payment-allocation-table {
            table {
                thead {
                    tr {
                        background: #e3e7f1;
                        border-radius: 6px;

                        th {
                            width: 14% !important;
                        }
                    }
                }

                tbody {
                    tr {
                        td {
                            width: 14% !important;

                        }
                    }
                }

            }
        }
    }
}

.category-select-bx {
    display: flex;
    align-items: center;
    margin-top: 20px;

    .single-select {
        width: 220px;
        margin-right: 10px;
        margin-bottom: 0px;
    }

    .react-select__control {
        height: 35px;
        border-color: #bbbbbb !important;
        border-radius: 8px !important;
        min-height: 35px !important;
    }

    .react-select__value-container {
        background: transparent !important;
    }

    .single-select {
        .react-select__value-container--has-value {
            .react-select__placeholder {
                top: -12px;
                background: #fff;
                transition: all 0.15s ease-in-out;
                margin-left: -2px;
                font-size: 12px;
                padding: 0 3px;
                width: auto;
                font-weight: 700;
            }
        }

        .react-select__control--menu-is-open,
        .react-select__control--is-focused {
            .react-select__placeholder {
                top: -12px;
                background: #fff;
                transition: all 0.15s ease-in-out;
                margin-left: -2px;
                font-size: 12px;
                padding: 0 3px;
                width: auto;
                font-weight: 700;
            }
        }
    }
}

.pointer-none.nav-item {
    pointer-events: none;
    opacity: 0.6;
}

.no-scrollable-popup {
    .modal {
        .modal-body {
            overflow: unset;
            max-height: inherit;
        }
    }
}

.verfication-img-no-image {
    background: #f4f6fb;
    border-radius: 10px;
    height: 275px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 80px;
    flex-direction: column;

    .no-img-txt {
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        color: $heading-color;
        padding-top: 20px;
    }
}

.inbound-financier-select {
    position: absolute;
    width: 85%;
    margin-top: -15px;

    .css-1g6gooi {
        margin-top: 0px !important;
    }

    .css-1pahdxg-control,
    .css-yk16xz-control {
        height: 32px !important;
        min-height: 32px !important;
    }
}

.fam-filter-toggle {
    width: 38px;
    height: 38px;
    border: 1px solid rgba($heading-color, 0.5);
    margin-left: 10px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;

    .fam-filter-applied {
        background: #23c133;
        width: 8px;
        height: 8px;
        border-radius: 100%;
        position: absolute;
        top: -3px;
        right: -2px;
    }

}

.outbound-filter {
    position: absolute;
    right: 0px;
}

.export-data-btn{ 
    position: absolute;
    right: 50px;
}

.tab-list {
    position: relative;
}

.btn-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .select-val-label {
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        padding-right: 30px;
        color: rgba($heading-color, 0.87);
    }
}

.ucf-txt {
    background: #3BC04D;
    border-radius: 4px;
    height: 20px;
    padding: 0px 5px;
    color: $white !important;
    font-weight: 500;
    font-size: 10px;
}

.ucrf-txt {
    background: #50ABFE;
    border-radius: 4px;
    height: 20px;
    padding: 0px 5px;
    color: $white !important;
    font-weight: 500;
    font-size: 10px;
}

.ubrf-txt {
    background: #ffa340;
    border-radius: 4px;
    height: 20px;
    padding: 0px 5px;
    color: $white !important;
    font-weight: 500;
    font-size: 10px;
}

.long_utr_number {
    word-break: break-all;
}

.popup-loader {
    position: absolute !important;
    top: 0%;
    background: rgba(0, 0, 0, 0.5);
    height: 100% !important;
}

.allocation-table-pending-data {
    position: relative;
}

.lost-lead {
    &.finance-report-outer {
        table {
            tr {

                th,
                td {
                    &:nth-child(1) {
                        width: 18%
                    }

                    &:nth-child(8) {
                        width: 25%
                    }
                }
            }
        }
    }
}

.finance-report-outer {
    .lost-payment-allocation {
        table {
            thead {
                tr {

                    th {
                        &:first-child {
                            border-radius: 6px 0 0 0px;
                        }

                        &:last-child {
                            border-radius: 0px 6px 0px 0;
                        }
                    }
                }
            }

            tbody {
                tr {
                    border-color: rgba($border-color, 0.3);

                    td {
                        &:nth-child(1) {
                            vertical-align: top;
                            border-left: 1px solid $border-color;
                        }

                        &:nth-child(2) {
                            border-right: 1px solid $border-color;
                            border-left: 1px solid $border-color;
                            padding: 0px;

                            table {

                                tr {
                                    border-color: $border-color;

                                    td {
                                        &:nth-child(1) {
                                            vertical-align: middle;
                                            border-left: 0px;
                                        }

                                        border-right: 0px;
                                        padding: 13px 15px;

                                        &:nth-child(5) {
                                            border-left: 1px solid $border-color;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .custom-checkbox {
            .custom-control-input:checked~.custom-control-label::after {
                font-size: 16px;
                top: 1px;
                left: 3px;

            }
        }
    }

    .lost-tranch-completed {
        table {
            thead {
                tr {
                    th {
                        &:first-child {
                            border-radius: 6px 0 0 0px;
                        }

                        &:last-child {
                            border-radius: 0px 6px 0px 0;
                        }
                    }
                }
            }

            tbody {
                tr {
                    border-color: rgba($border-color, 0.9);

                    td {
                        &:nth-child(1) {
                            vertical-align: top;
                            border-left: 1px solid $border-color;
                        }

                        &:nth-child(2) {
                            border-right: 1px solid $border-color;
                            border-left: 1px solid $border-color;
                            padding: 0px;

                            table {

                                tr {
                                    border-color: $border-color;

                                    td {
                                        &:nth-child(1) {
                                            vertical-align: middle;
                                            border-left: 0px;
                                        }

                                        border-right: 0px;
                                        padding: 13px 15px;

                                        &:nth-child(5) {
                                            border-left: 1px solid $border-color;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .material {
                .form-input {
                    margin-bottom: 0px;
                }
            }
        }
    }

    .p-b-0 {
        padding-bottom: 0px !important;
    }
}

.lost-utr-number {
    span {
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.lost-payment-allocation-popup {
    &.update-payment-popup {
        .modal {
            .modal-body {
                overflow-y: hidden;
            }

            .lost-payment-allocation {
                max-height: 250px;
                overflow-y: auto;
                width: calc(100% + 28px);
                padding-right: 20px;
            }
        }

    }
}

.css-1wa3eu0-placeholder {
    font-size: 11px;
    font-weight: bold;
}

.lad-error {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.alloction-dropdown-table {
    tbody {
        tr {
            border-color: rgba($heading-color, 0.5);

            td {
                &:nth-child(1) {
                    vertical-align: middle !important;

                }
            }

            .material {
                .form-input {
                    padding: 10px 15px;
                }
            }
        }
    }
}
.dealer-payment-history-popup{
    .modal-main {
        table{ 
            tbody{ 
                tr{ 
                    td{
                        text-align: left;
                        padding-left: 30px;
                        padding-right: 30px;
                    }
                }
            }
        }
    }
}

.finance-report-outer {
    .allocation-data-table {
        .other-trans-fields {
            align-items: flex-start;
            .material {
                .form-input {
                    margin-bottom: 0px;
                }
            }
        }
    }
}

.finance-report-outer{
    .report-table{
        .action-btn {
            display: flex;
            li {
                height: 32px;
                width: 32px;
                border-radius: 4px;
                border: 1px solid #e0e0e0;
                margin-right: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                cursor: pointer;
                &:last-child {
                    margin-right: 0px;
                }
            }
        }
    } 
}


    .action-btn {
        display: flex;
        
        li {
            &:hover{
            // background: $accent-color;
            color: $white;
            text-decoration: none;
            a{
                color: $white;
                text-decoration: none;
                background: $accent-color;
            }
        }
    }
    }


.search-panel-right {
    position: relative;

    input {
        border: 1px solid #bbbbbb;
        border-radius: 8px;
        padding: 10px 10px 10px 40px;
    }

    .search-icn {
        position: absolute;
        left: 11px;
        top: 8px;
        font-size: 24px;
    }
}

.dealer-wallet-payment-outer {

    .dealer-wallet-payment-detail {
        ul {
            display: flex;
            align-items: center;
            padding: 15px 0;
    
            li {
                padding-right: 40px;
    
                label {
                    font-weight: normal;
                    font-size: 11px;
                    line-height: 16px;
                    color: rgba($heading-color, 0.6);
                    display: block;
                }
    
                span {
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 24px;
                    color: $heading-color;
                    display: block;
                }
    
                .ellipsis-text {
                    max-width: 90px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
    }
}
.max-width{
    width: 336px!important;
}
.single-select-box {
    .react-select__placeholder {
        font-size: 11px;
    }
  
    .react-select__indicators {
        svg {
            display: none;
        }
    }
  
    .react-select__indicator-separator,
    .css-109onse-indicatorSeparator {
        display: none;
    }
  
    .react-select__control--menu-is-open,
    .react-select__control {
        height: 42px;
        border-radius: 8px !important;
        outline: none !important;
        box-shadow: none;
        border-color: #bbbbbb !important;
  
        &:hover {
            border-color: #bbbbbb !important;
            box-shadow: none;
            border-radius: 8px;
            outline: none !important;
        }
    }
  
    .react-select__indicators {
        &:after {
            content: "\e917";
            position: absolute;
            right: 3px;
            color: #000;
            font-family: dc !important;
            font-size: 24px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
  
    .react-select__single-value,
    .css-1v99tuv {
        font-size: 12px;
    }
  
    .react-select__menu-list,
    .css-11unzgr {
        font-size: 12px;
    }
  
    .css-1qprcsu-option,
    .css-1vr111p-option {
        font-weight: normal;
  
        input {
            position: relative;
            top: 3px;
        }
    }
  
    .react-select__single-value {
        max-width: 100%!important;
    }
  }

  .p-b-10{
    padding-bottom: 10px;
  }
  .popup-date-piker{
    .react-datepicker-wrapper{width: 100%;}
  }
  .finance-report-outer table th, .finance-report-outer table td{
    .maxWidth{
        max-width: 100px !important;
    }
  }

  .dealerAddress{
    margin-bottom: 20px;
    border: 1px solid $border-color;
    border-radius: 8px;
    position: relative;
    .dealerAddressHeading{ 
        background: #e3e7f1;
        color: $heading-color;
        font-size: 16px;
        padding: 10px;
        width: 100%;
        display: inline-block;
        border-radius: 8px;
        cursor: pointer;
        &::before{
            font-family: "dc";
            content: "\e90c";
            font-size: 20px;
            position: absolute;
            right: 10px;
            top: 11px;
        }
    }
    ul {
        li{
            display: flex;
            justify-content: space-between;
            padding: 10px 10px 0px 10px;
            span{
                min-width: 150px;
            }

        }
        padding-bottom: 10px;
    }
    
    &.HidedealerAddress{
        .dealerAddressHeading{
        &::before{
            content: "\e907";
            top: 11px;
        }
        }
        ul{
            display: none;
        }
    }
  }
   
  .litextright{
    text-align: right;
  }

  .mt-minus-23 {
    top: -23px !important;
  }

.subtable-scroll1 {
    thead {
        tr {
            background: #e3e7f1;
        }
    }

    td {
        background: rgba(239, 242, 249, 0.4);
        padding-left: 30px;
        padding-right: 30px;

        table {
            tr {
                th {
                    &:nth-child(1) {
                        width: 24%;
                    }
                }
            }
        }
    }

    table {
        width: 100%;
        overflow-y: auto;
        display: block;

        tbody {
            td {
                background: $white !important;
                padding-left: 15px !important;
                padding-right: 15px !important;
            }
        }
    }
}


.finance-report-outer {
    .subtable-scroll {
      overflow-x: auto;
      table{
          display: block;
      }
      thead {
          tr {
              background: #e3e7f1;
              th{
                &:last-child{
                border-radius:  0 !important;
                }
            }
              // th {
              //     width: 150px;
              //     min-width: auto;
              //     &:nth-child(4), &:nth-child(6){
              //         width: 180px;
              //     }
              //     &:nth-child(7){
              //         width: 130px;
              //     }
              // }
          }
      }
  
      td {
          background: rgba(239, 242, 249, 0.4);
          padding-left: 30px;
          padding-right: 30px;
  
          table {
              tr {
                  th {
                      width: 15% !important;
                      &:nth-child(1) {
                          width: 24%;
                      }
                  }
              }
          }
      }
  
      table {
          width: 100%;
  
          tbody {
              td {
                  background: $white !important;
                  
                  padding: 23px 15px !important;
                  white-space: nowrap;
              }
          }
      }
  }
    .action-table-data {
        vertical-align: top;
        min-width: auto !important;
        padding-left: 0px;
        thead {
            tr {
                background: #e3e7f1;
                th{
                    &:first-child{
                    border-radius: 0  !important;
                }
            }
                // th {
                //     width: 150px;
                //     min-width: auto;
                //     &:nth-child(4), &:nth-child(6){
                //         width: 180px;
                //     }
                //     &:nth-child(7){
                //         width: 130px;
                //     }
                // }
            }
        }
    
        td {
            background: rgba(239, 242, 249, 0.4);
            padding-left: 30px;
            padding-right: 30px;
    
            table {
                tr {
                    th {
                        width: 15% !important;
                        &:nth-child(1) {
                            width: 24%;
                        }
                    }
                }
            }
        }
    
        table {
            width: 100%;
    
            tbody {
                td {
                    background: $white !important;
                    padding-left: 15px !important;
                    padding-right: 15px !important;
                }
            }
        }
    }
    .p-r-0{
        padding-right: 0px;
    }
  }

.subtable-fixed{
    overflow-x: hidden !important;
    max-height: initial !important;
    width: 260px !important;
    th{
        height: 99px;
        vertical-align: top;
        &:nth-child(2) {
            width: 120px !important;
        }
    }
    td {
        padding: 8px 0px !important;
        input {
            width: 100px !important;
        }
    }
}

.table-border-right {
    border-radius: 0px !important;
    table {
        thead{
            tr{
                border-radius: 6px 0px 0 0 !important;
            }
        }
        th {
            &:first-child{
                position: sticky;
                left: 0px;
                // background: #e3e7f1;
                z-index: 9 !important;
            }
            &:last-child{
                border-radius: 0px !important;
            }
        }
        td {
            &.sticky-loan-id {
                position: sticky !important;
                left: 0px;
                background: white;
                z-index: 9 !important;
            }
        }
    }
}

.table-border-left {
    border-radius: 0px 8px 0 0 !important;
    table {
        thead{
            tr{
                border-radius: 0px 6px 0 0 !important;
            }
        }
        th {
            &:first-child{
                border-radius: 0px !important;
            }
        }
    }
}

.inbound-financier {
    table {
        thead {
            tr {
                th{
                    white-space: normal !important;
                }
            }
        }
        .w-9 {
            width: 9% !important;
        }
    }
}

.banner-allocation {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .additional-banner-message {
        padding: 8px 0px;
    }
}

.m-banner-top {
    margin-top: 0px !important;
}